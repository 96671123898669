import React from 'react'
import { PAGE_NOT_FOUND_IMAGE_PROPS } from '../../../config/constants'
import Image from '../Image'
import Layout from '../layout'
import SEO from '../SEO'
import { I_PageNotFoundProps } from './interfaces'
import * as notFoundStyles from "../../../styles/components/shared/404/index.module.css"
import { RootStateOrAny, useSelector } from 'react-redux'
import { navigate } from 'gatsby-link'

const PageNotFound: React.FC<I_PageNotFoundProps> = ({}): JSX.Element => {
  const currentTheme: string = useSelector((state: RootStateOrAny) => state.theme);

  const goBackHome = React.useCallback((e) => {
    e.preventDefault();
    navigate("/");
  }, [])

  return (
    <Layout style={{height: "100vh"}}>
      <SEO title={"Page Not Found · Brian Njogu"} />
      <div className={`container d-flex justify-content-center ${notFoundStyles.container}`}>
        <div className={`row ${notFoundStyles.row}`}>
          <div className={`col-12 d-flex justify-content-center ${notFoundStyles.title_container}`}>
            <h1 className={`${notFoundStyles.title} ${notFoundStyles[`title_${currentTheme}`]}`}>
              Whoops! Page not found.
            </h1>
          </div>
          <div className={`col-12 d-flex justify-content-center`}>
            <Image {...PAGE_NOT_FOUND_IMAGE_PROPS} />
          </div>
          <div className={`col-12 d-flex justify-content-center`}>
            <a className={`${notFoundStyles.cta} ${notFoundStyles[`cta_${currentTheme}`]}`} onClick={goBackHome}>
              Go back to home
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
} 

export default PageNotFound